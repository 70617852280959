<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <DataTable ref="dt" :value="receipts" 
            v-model:selection="selectedReceipt" dataKey="id" :showGridlines=true :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} receipts" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">{{$t('receipt.list_title')}}</h5>
							<IconField iconPosition="left">   
								<InputIcon>
									<i class="pi pi-search" />
								</InputIcon>             
								<InputText v-model="filters['global'].value" placeholder="Search..." />
							</IconField>
						</div>
					</template>
                    <Column headerStyle="width:8%; min-width:8rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="openReceiptEdit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteEntity(slotProps.data)" />
						</template>
					</Column>
					<Column field="code" :header="$t('shipment.shipmentCode')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							{{data.shipmentDto.shipmentCode}}
						</template>
					</Column>
					<Column field="code" :header="$t('shipment.referenceCode')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('shipmentDto.referenceCode')}}</span>
							{{data.shipmentDto.referenceCode}}
						</template>
					</Column>
					<Column field="code" :header="$t('warehouseReceipt.deliveryAgent')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.deliveryAgent')}}</span>
							{{data.deliveryAgent}}
						</template>
					</Column>
					<Column field="courierName" :header="$t('warehouseReceipt.courierName')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.courierName')}}</span>
							{{data.courierName}}
						</template>
					</Column>
					<Column field="courierPhone" :header="$t('warehouseReceipt.courierPhone')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.courierPhone')}}</span>
							{{data.courierPhone}}
						</template>
					</Column>
					<Column field="recipient" :header="$t('warehouseReceipt.recipient')"  headerStyle="width:8%; min-width:4rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.recipient')}}</span>
							{{data.recipient}}
						</template>
					</Column>
					<Column field="packageNumber" :header="$t('warehouseReceipt.packageNumber')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.packageNumber')}}</span>
							{{data.packageNumber}}
						</template>
					</Column>
					<Column field="note" :header="$t('warehouseReceipt.note')" :sortable="true">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.note')}}</span>
							{{data.note}}
						</template>
					</Column>
			</DataTable>
    </div>
  </div>
</template>
<script>
import CrudTable from '@/pages/common/CrudTable';
import ShipmentService from '@/service/ShipmentService';
import AuthService from '@/service/AuthService';
import adjustment from '@/utils/adjustment';
import { converter } from '@/utils/common';
import formatter from '@/utils/formatter';
import { Consts } from '@/utils/consts';
//import filter from '@/mixins/filter'

export default {
  components: {CrudTable},
  //mixins: [filter],
  data() {
    return {
      entities: [],
      packageCode: null,
      currentUser: null,
      metaTable : {
        entityName: "shipment",
        expander: false,
        searchable: true,
        rowEditor: true,
        editMode: "cell",
        actionsHeaderStyle: "min-width:12rem; text-align: center;",
        paginator:{
          enable: true,
          rows: 10,
          rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} shipments",
        },
        filter: {
          filterDisplay: "menu",
          globalFilterFields: ['shipmentCode','awbCode']
        },
        columns: [
          {
            name: "departure",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            dataType:"date",
            formatter: formatter.formatDate
          },
          {
            name: "arrivedDate",
            label: "shipment.arrived_date",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            dataType:"date",
            formatter: formatter.formatDate
          },
          {
            name: "shipmentCode",
            label: "shipment.code",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            link: this.openLink
          },
          {
            name: "awbCode",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true,
            editor: {
              enable: true,
              style:"width: 120px"
            },
          },
          // {
          //   name: "agentCode",
          //   label:"shipment.agent",
          //   headerStyle: "width:5%; min-width:5rem;",
          //   filter: true,
          //   condition:"user.orgType=='owner'"
          // },
          {
            name: "weightLbs",
            label:"shipment.weightAwb",
            headerStyle: "width:10%; min-width:12rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.formatLbsWeight
          },
          {
            name: "weightSystemLbs",
            label:"shipment.weightSystem",
            headerStyle: "width:10%; min-width:13rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.formatLbsWeight
          },
          {
            name: "weightActual",
            label:"shipment.actual_weight",
            headerStyle: "width:10%; min-width:13rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.formatLbsWeight
          },
          {
            name: "packages",
            label:"shipment.pieces",
            headerStyle: "width:10%; min-width:6rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.length
          },
          {
            name: "storedPieces",
            label:"shipment.stored_pieces",
            headerStyle: "width:10%; min-width:6rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.length
          },
          {
            name: "deliveredPieces",
            label:"shipment.delivered_pieces",
            headerStyle: "width:10%; min-width:6rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.length
          },
          {
            name: "inventory",
            label:"shipment.inventory_pieces",
            headerStyle: "width:10%; min-width:6rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.length
          },
          // {
          //   name: "status",
          //   headerStyle: "width:10%; min-width:10rem;",
          //   filter: true,
          // },
          {
            name: "note",
            headerStyle: "width:20%; min-width:18rem;",
            filter: true,
          }
        ],
        tableActions: [
          {
            name: "filterclear",
            icon: "pi pi-filter-slash",
            style: "p-button-outlined mr-2"
          },
        ],
        itemActions: [
          // {
          //   name: "edit",
          //   icon: "pi pi-pencil",
          //   style: "p-button-rounded mr-2",
          //   condition: "status != 'stored'"
          // },
          // {
          //   name: "preview",
          //   icon: "pi pi-print",
          //   style: "p-button-rounded mr-2"
          // },
          // {
          //   name: "confirm",
          //   icon: "pi pi-send",
          //   style: "p-button-rounded mr-2",
          //   condition:"status === 'inited'"
          // }
        ]
      }
    }
  },
  mounted() {
    //this.initColumnFilters(this.$refs.dt, this.filters);
    this.currentUser = AuthService.currentUser;
    this.service = ShipmentService;
    this._loadPackages(0, Consts.DEFAULT_PAGE_ROWS);
  },
  methods: {
    onPaging(pageEvent) {
			this._loadPackages(pageEvent.page, pageEvent.rows);
		},
    _loadPackages(page, size) {
      this.service.getAll({page, size}).then(data => {
				if(Array.isArray(data.content)) {
          data.content.forEach(item => adjustment.adjustShipment(item));
          this.entities = data.content;
          this.metaTable.paginator.totalRecords = data.totalElements;
        }
			});
    },
    onTableAction(sAction) {
      if(sAction == 'create') {
        this.$router.push({ name: 'agent_shipment_edit', params: {} });
      }
      console.log(sAction);
    },
    onItemAction(sAction, entity) {
      if(sAction == 'edit') {
        this.$router.push({ name: 'agent_shipment_edit', params: {id: entity.id} });
      } else if (sAction == 'preview') {
        //this.$router.push({ name: 'print_preview', params: {resource: "shipment", id: entity.id} });
        const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: entity.id} });
        window.open(routeData.href);         
      } else if (sAction == 'confirm') {
        this.onSendShipment(entity);
      } else if (sAction == 'delete') {
        //this.onDeleteShipment(entity);
      }
    },
    onSendShipment(entity) {
      let error = "";
      if (!entity.departure) {
        error = this.$t('shipment.missing_departure_date');
      } else if (!entity.awbCode) {
        error = this.$t('shipment.missing_awb_code');
      } 
      if (error) {
        this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000});
      } else {
        this.$confirm.require({
            message: this.$t("shipment.confirm_start"),
            header: this.$t("common.confirmation"),
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              this._sendShipment(entity);
            },
            reject: () => {
              //callback to execute when user rejects the action
            },
          });        
      }      
    },
    _sendShipment(entity) {
      this.service.startShipment(entity.id).then(()=>{
          this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('shipment.confirmed'), life: 3000});
        }).catch((e) => {
          console.log(e);
        });
    },
    // onDeleteShipment(entity) {
    //   this.$confirm.require({
    //     message: this.$t("shipment.confirm_delete"),
    //     header: this.$t("common.confirmation"),
    //     icon: "pi pi-exclamation-triangle",
    //     accept: () => {
    //       this._deleteShipment(entity);
    //     },
    //     reject: () => {
    //       //callback to execute when user rejects the action
    //     },
    //   });        
    // },
    // _deleteShipment(entity) {
    //   this.service.delete(entity.id).then(()=>{
    //       this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('shipment.deleted_successfully'), life: 3000});
    //     });
    // },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
          case 'weightLbs':
          case 'weightKg':
              if (this.isPositiveInteger(newValue)) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;

          default:
              if (newValue != null && newValue.trim().length > 0) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              }
          break;
      }
    },
    isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    },
    openLink(data) {
      this.$router.push({ name: 'user_shipment_detail', params: {id: data.id} })
    }
  }
}
</script>
